<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="isError">
      <v-icon color="red">mdi-alert-outline</v-icon>
      <span class="red--text pl-3">{{ this.errorMessage }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-overlay color="primary" :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row class="pb-2">
        <v-col cols="12" sm="6" md="4" lg="3">
          <h2>{{ name }}</h2>
          <p class="text-body-1 font-italic text--secondary mb-1">
            Saved On: {{ dateSubmittedFormatted }}
          </p>
          <p class="text-body-1 font-italic text--secondary mb-1">
            Updated On: {{ dateUpdatedFormatted }}
          </p>
          <div class="mb-2 scroll-multiline">
            <p
              class="text-body-1 font-italic text--secondary mb-0"
              v-for="(note, i) in noteArr"
              :key="note + i"
            >
              {{ note }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="9" class="d-flex justify-end">
          <v-col cols="auto">
            <v-btn
              outlined
              block
              @click="nav('/SavedCoverLetters')"
              class="mb-2"
            >
              Back to Your Cover Letters
            </v-btn>
            <v-btn outlined block @click="handleDownloadDocx()" class="mb-2">
              Download .docx
            </v-btn>
            <v-btn outlined block @click="handleDownloadPDF()" class="mb-2">
              Download PDF
            </v-btn>
            <v-btn outlined block @click="triggerRemove()" class="mb-2">
              Delete Saved Cover Letter</v-btn
            >
          </v-col>
        </v-col>
      </v-row>
      <v-system-bar color="accent" height="5" class="mb-4"></v-system-bar>
      <review-comment
        v-if="!loading"
        :currentHref="`/CoverLetterView/${id}`"
        :imageRef="imageRef"
      ></review-comment>
    </v-container>
    <r-dialog
      v-model="removeDialog"
      title="Confirm Removal"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          Are you sure you want to remove this saved resume?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined @click="removeDialog = false"
          >Cancel</v-btn
        >
        <v-btn color="primary" outlined @click="removeCoverLetter"
          >Remove</v-btn
        >
      </v-card-actions>
    </r-dialog>
    <r-dialog v-model="premiumBanner" persistent>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <h4 class="pt-2 text-center">
          Oops! Looks like you will need Resumation Plus to use Cover Letter
          Creator feature!
        </h4>
        <p class="mt-2">
          Upgrade to get unlimited use of the Optimize with AI features,
          hide/show parts of your resume in-tool (customizations), save and
          store multiple resumes, and much more for only $5 a month!
        </p>
        <h4 class="pt-2 text-center">
          Would you like to upgrade to Resumation Plus?
        </h4>
      </v-card-text>
      <v-card-actions
        :class="
          $vuetify.breakpoint.xsOnly
            ? 'flex-wrap justify-center'
            : 'flex-wrap justify-end'
        "
      >
        <v-btn color="gray" outlined text @click="noSubscription()">
          Not Now
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined text @click="navToPremiumPage()">
          Yes - let's do it!
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </div>
</template>

<script>
import ReviewAndComment from "../../components/ReviewAndComment.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as dUtil from "../../utility/dateutil";

// @ is an alias to /src
export default {
  name: "CoverLetterView",
  data: () => ({
    premiumBanner: false,
    errorOnLoad: false,
    removeDialog: false,
    loading: true,
    removeId: null,
    id: null,
    showError: false,
    errorMessage: "Test",
  }),
  components: {
    "review-comment": ReviewAndComment,
  },
  methods: {
    noSubscription() {
      this.$router.back();
    },
    async loadData() {
      try {
        this.removeId = this.$route.params.key;
        await this.loadCoverLetter({ reviewId: this.removeId });
        if (this.isError) {
          this.errorMessage = "Error loading cover letter";
          this.showError = true;
        }
        this.loading = false;
      } catch (e) {
        this.showError = true;
        this.errorMessage = "Error loading cover letter";
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    async handleDownloadDocx() {
      this.downloadFileFromUrl(this.fileRef);
    },
    async handleDownloadPDF() {
      this.downloadFileFromUrl(this.pdfRef);
    },
    downloadFileFromUrl(Ref) {
      const link = document.createElement("a");
      link.href = Ref;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    triggerRemove() {
      this.removeDialog = true;
    },
    async removeCoverLetter() {
      try {
        this.loading = true;
        await this.deleteCoverLetter({ reviewId: this.removeId });
        if (this.isError) {
          this.showError = true;
          this.errorMessage = "Error deleting cover letter";
        } else {
          this.nav("/SavedCoverLetters");
        }
      } finally {
        this.removeDialog = false;
        this.loading = false;
      }
    },
    ...mapActions("activeCoverLetter", ["loadCoverLetter"]),
    ...mapActions("activeCoverLetter", ["deleteCoverLetter"]),
    ...mapActions("auth", ["refreshPremium"]),
  },
  computed: {
    ...mapFields("auth", {
      isPremium: "isPremium",
    }),
    ...mapFields("activeCoverLetter", [
      "name",
      "note",
      "dateSaved",
      "dateUpdated",
      "imageRef",
      "fileRef",
      "pdfRef",
      "isError",
    ]),
    dateSubmittedFormatted() {
      return dUtil.dateStringToFormat(this.dateSaved);
    },
    dateUpdatedFormatted() {
      return dUtil.dateStringToFormat(this.dateUpdated);
    },
    noteArr() {
      if (this.note == null || this.note == "") {
        return [];
      }

      let noteSplit = this.note.split("\n");
      if (noteSplit.length == 1) {
        noteSplit[0] = "Note: " + noteSplit[0];
      } else {
        noteSplit.unshift("Note: ");
      }

      return noteSplit;
    },
  },
  async mounted() {
    await this.refreshPremium();
    if (!this.isPremium) {
      this.dialog = false;
      this.premiumBanner = true;
    }
    this.isError = false;
    this.errorMessage = "";
    this.loading = true;
    await this.loadData();
  },
};
</script>
